import create from "zustand";
import { EMPTY_FN } from "../../../constants";
import { PARCEL_STATUS } from "../types/ParcelsResponse";

const InitialParcelStatuses: Record<PARCEL_STATUS, boolean> = {
  APPROVED: true,
  REFUSED: true,
  NOT_ASSIGNED: true,
  NOTIFIED: true,
  CONCERNED: true,
};

export const parcelSelectOptions = [
  { label: "Notified", value: PARCEL_STATUS.NOTIFIED },
  { label: "Concerned", value: PARCEL_STATUS.CONCERNED },
  { label: "Refused", value: PARCEL_STATUS.REFUSED },
  { label: "Unassigned", value: PARCEL_STATUS.NOT_ASSIGNED },
  { label: "Approved", value: PARCEL_STATUS.APPROVED },
];

export type ParcelsStore = {
  selectedId: Nullable<string>;
  parcelLayerHidden: boolean;
  selectedStatuses: Record<string, boolean>;
  isAllSelected: boolean;
  toggleParcelLayer: () => void;
  resetStore: () => void;
  setSelectedId: (id: Nullable<string>) => void;
  toggleSelectedStatus: (status: PARCEL_STATUS) => void;
  toggleAll: () => void;
  selectedMultipleIds: Array<Nullable<string>>;
  setMultipleSelectedIds: (id: any) => void;
  setParcelLayer: (value: boolean) => void;
};

const INITIAL_STATE = {
  selectedId: null,
  resetStore: EMPTY_FN,
  parcelLayerHidden: true,
  selectedStatuses: InitialParcelStatuses,
  isAllSelected: true,
  selectedMultipleIds: [],
};

export const useParcelsStore = create<ParcelsStore>((set, get) => ({
  ...INITIAL_STATE,
  resetStore: () => set({ ...INITIAL_STATE }),
  setSelectedId: (id) => {
    if (id === get().selectedId) {
      return;
    }
    set({ selectedId: id });
    // if one parcel is selected, empty the multiselected parcels
    set({ selectedMultipleIds: [] });
  },
  toggleParcelLayer: () => {
    set({ parcelLayerHidden: !get().parcelLayerHidden });
  },
  toggleSelectedStatus: (status) => {
    const statuses = { ...get().selectedStatuses };
    statuses[status] = !statuses[status];
    set({ isAllSelected: isAllChecked(statuses), selectedStatuses: statuses });
  },
  toggleAll: () => {
    const isAllSelected = get().isAllSelected;
    const selectedStatuses = { ...get().selectedStatuses };
    if (!selectedStatuses || !Object.keys(selectedStatuses).length) {
      return;
    }
    Object.keys(selectedStatuses).forEach((key) => {
      selectedStatuses[key] = !isAllSelected;
    });
    set({ selectedStatuses, isAllSelected: !isAllSelected });
  },
  setMultipleSelectedIds: (id: Nullable<string>) => {
    set({ selectedMultipleIds: [...get().selectedMultipleIds, id] });
  },
  setParcelLayer: (value: boolean) => {
    set({ parcelLayerHidden: value });
  },
}));

const isAllChecked = (statuses: Record<string, boolean>) => {
  if (!statuses || !Object.keys(statuses).length) {
    return false;
  }

  return Object.keys(statuses).length === Object.keys(statuses).filter((key) => statuses[key])?.length;
};

export default useParcelsStore;
